import './styles.scss';
import Grid from '../../assets/logos/grid.svg';
import Beyond from '../../assets/logos/beyond.svg';
import Eyeque from '../../assets/logos/eyeque.svg';
import PNM from '../../assets/logos/PNM.svg';
import Thrive from '../../assets/logos/thrive.svg';
import Thryv from '../../assets/logos/Thryv.svg';
import Artist from '../../assets/logos/artistongo.svg';
import Splainers from '../../assets/logos/splainers.svg';
import Heimdall from '../../assets/logos/heimdall.svg';

import Brown from '../../assets/logos/brown.svg';
import Chicago from '../../assets/logos/chicago.svg';
import Columbia from '../../assets/logos/columbia.svg';
import Cornell from '../../assets/logos/cornell.svg';
import Harvard from '../../assets/logos/harvard.svg';
import Howard from '../../assets/logos/howard.svg';
import Penn from '../../assets/logos/penn.svg';
import Princeton from '../../assets/logos/princeton.svg';
import Stanford from '../../assets/logos/stanford.svg';
import Texas from '../../assets/logos/texas.svg';
import Yale from '../../assets/logos/yale.svg';
import Massa from '../../assets/logos/Massa.svg';

import Grad1 from '../../assets/1.png';
import Grad2 from '../../assets/2.png';
import Grad3 from '../../assets/3.png';
import Grad4 from '../../assets/4.png';

const PioneersEducation = () => {
  return (
    <div className='pioneersSection' id='pioneers'>
      <h1 className='pioneersTitle'>Pioneers Education</h1>
      <span className='pioneersText'>
        Pioneers Education is dedicated to connecting forward-thinking companies with the brightest early-career professionals. We
        believe in empowering the next generation of talent and the organizations that will help them flourish.
      </span>
      <div className='pioneersCardsHolder'>
        <div className='pioneersCard'>
          <img className='pioneersCardBack' src={Grad1} alt='background' />
          <span className='pioneersCardTitle'>Effortless Job Definition</span>
          <span className='pioneersCardText'>
            Simply tell us who you need, and we'll craft the perfect job description. No formal JD? No problem. We're here to
            streamline every step.
          </span>
          <span className='pioneersCardCount'>01.</span>
        </div>
        <div className='pioneersCard'>
          <img className='pioneersCardBack' src={Grad2} alt='background' />
          <span className='pioneersCardTitle'>Comprehensive Talent Acquisition</span>
          <span className='pioneersCardText'>
            We handle the sourcing, screening, and interviewing, bringing you a curated list of the top 4-5 candidates for your
            review, ensuring a perfect fit for your needs.
          </span>
          <span className='pioneersCardCount'>02.</span>
        </div>
        <div className='pioneersCard'>
          <img className='pioneersCardBack' src={Grad3} alt='background' />
          <span className='pioneersCardTitle'>Flexible Engagement </span>
          <span className='pioneersCardText'>
            There's no commitment required until you find the ideal candidate. With competitive hourly rates and no hidden fees,
            you gain exceptional talent on your terms.
          </span>
          <span className='pioneersCardCount'>03.</span>
        </div>
        <div className='pioneersCard'>
          <img className='pioneersCardBack' src={Grad4} alt='background' />
          <span className='pioneersCardTitle'>Full Compliance, No Hassles</span>
          <span className='pioneersCardText'>
            We manage all the employment paperwork and legalities, ensuring full compliance so you can focus on what matters most
            — growing your business.
          </span>
          <span className='pioneersCardCount'>04.</span>
        </div>
      </div>
      <div className='pioneersTrust'>
        <div className='pioneersTrust universitiesOverlay'>
          <span className='pioneersTrustTitle'>Trusted by</span>
          <div className='universitiesHolder'>
            <div className='universitiesHolderRow'>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Thryv} alt='logo' />
                </span>
                <span className='university'>
                  <img src={PNM} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Eyeque} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Thrive} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Beyond} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Grid} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Splainers} style={{ marginTop: '-20px' }} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Heimdall} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Artist} alt='logo' />
                </span>
              </div>
              <div className='universityBox'>
                <span className='university'>
                  <img src={Thryv} alt='logo' />
                </span>
                <span className='university'>
                  <img src={PNM} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Eyeque} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Thrive} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Beyond} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Grid} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Splainers} style={{ marginTop: '-20px' }} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Heimdall} alt='logo' />
                </span>
                <span className='university'>
                  <img src={Artist} alt='logo' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pioneersTrust universitiesOverlay'>
        <span className='pioneersTrustTitle'>We proudly serve students of the following universities</span>
        <div className='universitiesHolder'>
          <div className='universitiesHolderRow'>
            <div className='universityBox'>
              <span className='university'>
                <img src={Stanford} alt='logo' />
              </span>
              <span className='university'>
                <img src={Cornell} alt='logo' />
              </span>
              <span className='university'>
                <img src={Harvard} alt='logo' />
              </span>
              <span className='university'>
                <img src={Princeton} alt='logo' />
              </span>
              <span className='university'>
                <img src={Brown} alt='logo' />
              </span>
              <span className='university'>
                <img src={Howard} alt='logo' />
              </span>
              <span className='university'>
                <img src={Texas} alt='logo' />
              </span>
              <span className='university'>
                <img src={Massa} alt='logo' />
              </span>
              <span className='university'>
                <img src={Penn} alt='logo' />
              </span>
              <span className='university'>
                <img src={Chicago} alt='logo' />
              </span>
              <span className='university'>
                <img src={Yale} alt='logo' />
              </span>
              <span className='university'>
                <img src={Columbia} alt='logo' />
              </span>
            </div>
            <div className='universityBox'>
              <span className='university'>
                <img src={Stanford} alt='logo' />
              </span>
              <span className='university'>
                <img src={Cornell} alt='logo' />
              </span>
              <span className='university'>
                <img src={Harvard} alt='logo' />
              </span>
              <span className='university'>
                <img src={Princeton} alt='logo' />
              </span>
              <span className='university'>
                <img src={Brown} alt='logo' />
              </span>
              <span className='university'>
                <img src={Howard} alt='logo' />
              </span>
              <span className='university'>
                <img src={Texas} alt='logo' />
              </span>
              <span className='university'>
                <img src={Massa} alt='logo' />
              </span>
              <span className='university'>
                <img src={Penn} alt='logo' />
              </span>
              <span className='university'>
                <img src={Chicago} alt='logo' />
              </span>
              <span className='university'>
                <img src={Yale} alt='logo' />
              </span>
              <span className='university'>
                <img src={Columbia} alt='logo' />
              </span>
            </div>
          </div>
        </div>
      </div>
      <a className='aboutButton' href='https://pioneers-education.com/' target='_blank' rel='noreferrer'>
        Learn More
        <svg className='aboutButtonIcon' viewBox='0 0 36 26' fill='none'>
          <path d='M22.1326 1.06787C22.4336 5.12205 25.4284 13.1836 34.9998 12.9965' stroke='#fff' stroke-width='3.3' />
          <path d='M22.1326 24.9316C22.4336 20.8775 25.4284 12.8159 34.9998 13.003' stroke='#fff' stroke-width='3.3' />
          <path d='M35 12.9995L0.000244141 12.9995' stroke='#fff' stroke-width='3.3' />
        </svg>
      </a>
    </div>
  );
};

export default PioneersEducation;
