import { useState } from 'react';
import '../common.scss';
import Leo from './Leo.png';
import Victor from './Victor.png';
import cn from 'classnames';
import MainScreen from '../../components/MainScreen';
import Products from '../../components/Products';
import PioneersEducation from '../../components/PioneersEducation';
import video from '../../assets/1.mp4';
import Dave from '../../assets/Dave.png';
import Howard from '../../assets/Howard.png';
import Carly from '../../assets/Carly.png';
import Back1 from '../../assets/11.png';
import Back2 from '../../assets/22.png';
import Back3 from '../../assets/33.png';

const Investors = ({ onFormClick }) => {
  const [isLeoMore, setIsLeoMore] = useState(false);
  const [isVictorMore, setIsVictorMore] = useState(false);

  return (
    <div>
      <MainScreen onFormClick={onFormClick} />
      <video src={`${video}#t=0.1`} className='videoBack' muted autoPlay loop playsinline />
      <div className='fullScreenSection missionSection'>
        <p className='secondCreenDetails'>
          Our mission is to <span>reshape recruitment processes</span> through the innovative application of
          <span>Artificial Intelligence</span> and <span>Machine Learning</span>. We believe in the power of technology to
          revolutionize the way companies find, assess, and hire top talent.
        </p>
      </div>
      <Products />

      <div className='companyScreenSection' id='company'>
        <h1 className='firstScreenTitle'>Company</h1>
        <p className='companyScreenDetails'>
          In a world where talent acquisition is becoming more competitive than ever, Pioneers is stepping up to the challenge by
          reimagining the recruitment process. We are an <span>AI-driven recruitment firm,</span> uniquely blending artificial
          intelligence and human expertise to <span>simplify and elevate the hiring process.</span> By harnessing advanced
          algorithms and large language models, Pioneers improves efficiency, eliminates biases, and ensures precision in
          candidate matching.
        </p>
      </div>
      <div className='teamSection'>
        <h1 className='firstScreenTitle' style={{ textAlign: 'center' }}>
          Meet the Team
        </h1>
        <div className='teamsHolder'>
          <div className='teamItem'>
            <img className='teamPhoto' src={Leo} alt='Leo' />
            <span className='teamName'>Leo Shangin</span>
            <span className='teamRole'>Co-Founder and CEO</span>
            <div className='teamTags'>
              <span className='teamTag'>Business Development</span>
              <span className='teamTag'>Operations</span>
              <span className='teamTag'>Investor Relations</span>
            </div>
            <a className='teamLink' href='https://www.linkedin.com/in/leonidshangin' target='_blank' rel='noreferrer'>
              <span>
                <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M3.50527 1.82335C3.50527 2.69795 2.87691 3.39775 1.86795 3.39775H1.84944C0.878039 3.39775 0.25 2.69795 0.25 1.82335C0.25 0.930239 0.897139 0.25 1.887 0.25C2.87691 0.25 3.48649 0.930239 3.50527 1.82335ZM3.31465 4.64101V13.7498H0.421095V4.64101H3.31465ZM13.7499 13.7498L13.75 8.52708C13.75 5.72932 12.3225 4.42714 10.4184 4.42714C8.8821 4.42714 8.19426 5.31122 7.81025 5.93143V4.64129H4.91632C4.95447 5.49599 4.91632 13.75 4.91632 13.75H7.81025V8.66292C7.81025 8.39069 7.82903 8.11913 7.90554 7.92421C8.11467 7.38036 8.59082 6.81729 9.39022 6.81729C10.4377 6.81729 10.8564 7.65254 10.8564 8.87657V13.7498H13.7499Z'
                    fill='black'
                  />
                </svg>
              </span>
              LinkedIn
            </a>
            {isLeoMore ? (
              <p className='teamText'>
                <span>
                  Leo Shangin is the visionary co-founder of Pioneers, launched in 2021 following the successful development and
                  sale of Sailplay, a leading B2C marketing automation platform for the retail and restaurant sector acquired by
                  Retail Rocket. The inspiration for Pioneers sparked during his time at Columbia Business School, recognizing a
                  pivotal gap between academic offerings and real-world employer expectations. Leveraging his deep-rooted
                  expertise in mathematics and enriched by years of corporate experience with industry giants like Ernst & Young
                  and McKinsey, Leo pioneered an AI-driven platform. Today, this platform is an essential tool for top-tier
                  institutions such as MIT Sloan, Columbia University, and Chicago Booth, and is also trusted by powerhouse
                  businesses including Thryv and PNM Resources.
                </span>
                <span>
                  Leo co-founded Pioneers in 2021 after successfully building and selling{' '}
                  <a href='https://sailplay.net/' target='_blank' rel='noreferrer'>
                    Sailplay
                  </a>{' '}
                  - a B2C marketing automation platform for retail and restaurant businesses (acquired by{' '}
                  <a href='https://retailrocket.net/' target='_blank' rel='noreferrer'>
                    Retail Rocket
                  </a>{' '}
                  in 2021).
                </span>
                <span>
                  The idea of Pioneers came to Leo while he was in Columbia Business School upon realizing there was a significant
                  gap between employer needs and higher education. He wanted to help students find and launch careers best suited
                  for them, and to not only participate in talent search, but to also support students throughout their
                  internships by providing coaching and access to an extensive database. Together with his partner{' '}
                  <a href='https://www.linkedin.com/in/novikovvs/' target='_blank' rel='noreferrer'>
                    Victor
                  </a>
                  , whom he met while working at McKinsey in 2012, Leo started building an AI powered platform that is now widely
                  utilized in major ivy league universities like MIT Sloan, Columbia University and Chicago Booth as well as many
                  successful businesses like
                  <a href='https://www.thryv.com/' target='_blank' rel='noreferrer'>
                    Thryv
                  </a>{' '}
                  and{' '}
                  <a href='https://www.pnmresources.com/' target='_blank' rel='noreferrer'>
                    PNM Resources
                  </a>
                  .
                </span>
                <span>In Pioneers, Leo oversees business development, operations, fundraising and investor relations.</span>
                <span>
                  Leo has an extensive background in mathematics including a master’s degree from Lomonosov Moscow State
                  University. During his childhood spent in a small Northern town located just 250 miles from the Arctic Circle,
                  he was a winner of numerous math Olympiads including the IMO. Leo believes that deep knowledge of math not only
                  taught him to make sense of the world and get to global competence but helped him reconsider the typical content
                  in atypical ways as well as understand and build machine learning algorithms and large language models. Prior to
                  entering the world of start-ups, Leo had several years of corporate experience in Ernst and Young and McKinsey.
                </span>
                <span>
                  Leo lives in New York City with his wife and son, and during the weekends you will most likely find him at a
                  chess tournament supporting his prodigy chess-player child who at the age of 9 is the National US chess champion
                  as well as champion of both New York City and State and a member of the official US Chess Federation Team.
                </span>
                <span onClick={() => setIsLeoMore(false)} className='teamTextLess'>
                  Read less
                </span>
              </p>
            ) : (
              <p className='teamText'>
                <span>
                  Leo Shangin is the visionary co-founder of Pioneers, launched in 2021 following the successful development and
                  sale of Sailplay, a leading B2C marketing automation platform for the retail and restaurant sector acquired by a
                  Private Equity firm. The inspiration for Pioneers sparked during his time at Columbia Business School,
                  recognizing a pivotal gap between academic offerings and real-world employer expectations. Leveraging his
                  deep-rooted expertise in mathematics and enriched by years of corporate experience with industry giants like
                  Ernst & Young and McKinsey, Leo pioneered an AI-driven platform. Today, this platform is an essential tool for
                  top-tier institutions such as MIT Sloan, Columbia University, and Chicago Booth, and is also trusted by
                  powerhouse businesses including Thryv and PNM Resources.
                </span>
                <span onClick={() => setIsLeoMore(true)} className='teamTextMore'>
                  Read more
                </span>
              </p>
            )}
          </div>

          <div className='teamItem'>
            <img className='teamPhoto' src={Victor} alt='Victor' />
            <span className='teamName'>Victor Novikov</span>
            <span className='teamRole'>Co-Founder and CTO</span>
            <div className='teamTags'>
              <span className='teamTag'>Product Development</span>
              <span className='teamTag'>Engineering</span>
              <span className='teamTag'>Marketing</span>
            </div>
            <a className='teamLink' href='https://www.linkedin.com/in/novikovvs' target='_blank' rel='noreferrer'>
              <span>
                <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M3.50527 1.82335C3.50527 2.69795 2.87691 3.39775 1.86795 3.39775H1.84944C0.878039 3.39775 0.25 2.69795 0.25 1.82335C0.25 0.930239 0.897139 0.25 1.887 0.25C2.87691 0.25 3.48649 0.930239 3.50527 1.82335ZM3.31465 4.64101V13.7498H0.421095V4.64101H3.31465ZM13.7499 13.7498L13.75 8.52708C13.75 5.72932 12.3225 4.42714 10.4184 4.42714C8.8821 4.42714 8.19426 5.31122 7.81025 5.93143V4.64129H4.91632C4.95447 5.49599 4.91632 13.75 4.91632 13.75H7.81025V8.66292C7.81025 8.39069 7.82903 8.11913 7.90554 7.92421C8.11467 7.38036 8.59082 6.81729 9.39022 6.81729C10.4377 6.81729 10.8564 7.65254 10.8564 8.87657V13.7498H13.7499Z'
                    fill='black'
                  />
                </svg>
              </span>
              LinkedIn
            </a>
            {isVictorMore ? (
              <p className='teamText'>
                <span>
                  Victor, the dynamic force behind Pioneers' Product division, orchestrates a synergistic blend of data
                  scientists, engineers, and designers to continually refine the company's AI-driven core. Having gained
                  experience running tech teams at powerhouses such as Google, JP Morgan Chase, and Capital One Bank, combined
                  with managerial expertise from McKinsey, Victor's leadership approach is deeply rooted in industry knowledge.
                  Moreover, his entrepreneurial spirit shines through his 2014 endeavor, 'Dry Harder', a revolutionary laundry
                  service that raised industry standards before its acquisition. A mathematics enthusiast from a young age, Victor
                  seamlessly fuses academic rigor with pragmatic business acumen.
                </span>
                <span>
                  Victor co-founded Pioneers because its idea resonated with his passion for helping young people who were just
                  starting to build their careers. He saw a business opportunity in helping students and young professionals
                  thrive, and at the same time allowing businesses to complete their projects by utilizing a talented and
                  affordable workforce under the supervision of experienced mentors.
                </span>

                <span>
                  At Pioneers, Victor is in charge of the Product. He is the brain and the main engine behind the teams of data
                  scientists, engineers, designers and design strategists that build and improve the AI platform that is in the
                  core of the company. Victor brings to the table his extensive background and expertise gained while leading
                  teams of data scientists and product developers at Google, JP Morgan Chase, and Capital One Bank as well as
                  business management skills from working at McKinsey.
                </span>

                <span>
                  Victor is not new to the start-up industry. In 2014, he founded Dry Harder, an innovative premium dry cleaning,
                  laundry, and clothing alterations service delivered to the client’s door that took the regular dry cleaning to a
                  new level. The company was a pioneer in its field and was acquired by an industry veteran.
                </span>

                <span>
                  From a very young age, Victor was passionate about mathematics and science. At 14, he was accepted to the most
                  elite Russian math school under the umbrella of Moscow State University that only accepted 60 gifted students
                  from the entire 140+ million population country per year, and he moved to Moscow from a small provincial town.
                  While in school, Victor was influenced by the works of Andrey Kolmogorov, a prominent Soviet mathematician best
                  known for his foundational work in the theory of probability. Victor believes that the methods and techniques
                  developed by Kolmogorov and other mathematicians in the realm of probability and statistics provided him with
                  tools for problem-solving in diverse scenarios, from scientific research to business decision-making.
                </span>

                <span>
                  Victor holds two master’s degrees - in management and economics from Moscow State University and in applied
                  mathematics from Moscow Institute of Physics and Technology.
                </span>

                <span>
                  Victor lives in New York City. He is a frequent guest at various jazz clubs and Carnegie Hall, and has a keen
                  appreciation for art. Victor is a passionate photographer and avid traveler.
                </span>
                <span onClick={() => setIsVictorMore(false)} className='teamTextLess'>
                  Read less
                </span>
              </p>
            ) : (
              <p className='teamText'>
                <span>
                  Victor, the dynamic force behind Pioneers' Product division, orchestrates a synergistic blend of data
                  scientists, engineers, and designers to continually refine the company's AI-driven core. Having gained
                  experience running tech teams at powerhouses such as Google, JP Morgan Chase, and Capital One Bank, combined
                  with managerial expertise from McKinsey, Victor's leadership approach is deeply rooted in industry knowledge.
                  Moreover, his entrepreneurial spirit shines through his 2014 endeavor, a revolutionary laundry service that
                  raised industry standards before its acquisition. A mathematics enthusiast from a young age, Victor seamlessly
                  fuses academic rigor with pragmatic business acumen.
                </span>
                <span onClick={() => setIsVictorMore(true)} className='teamTextMore'>
                  Read more
                </span>
              </p>
            )}
          </div>
        </div>
        <div className='teamAdvisorsOverlay'>
          <div className='teamAdvisors'>
            <div className='teamAdvisor'>
              <div className='teamAdvisorPhotoOverlay'>
                <img className='teamAdvisorPhoto' src={Dave} alt='Advisor' />
              </div>
              <span className='teamAdvisorName'>David Begun</span>
              <span className='teamAdvisorRole'>Engineering</span>
              <a className='teamAdvisorLink' href='https://www.linkedin.com/in/davebegun' target='_blank' rel='noreferrer'>
                <span>
                  <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.50527 1.82335C3.50527 2.69795 2.87691 3.39775 1.86795 3.39775H1.84944C0.878039 3.39775 0.25 2.69795 0.25 1.82335C0.25 0.930239 0.897139 0.25 1.887 0.25C2.87691 0.25 3.48649 0.930239 3.50527 1.82335ZM3.31465 4.64101V13.7498H0.421095V4.64101H3.31465ZM13.7499 13.7498L13.75 8.52708C13.75 5.72932 12.3225 4.42714 10.4184 4.42714C8.8821 4.42714 8.19426 5.31122 7.81025 5.93143V4.64129H4.91632C4.95447 5.49599 4.91632 13.75 4.91632 13.75H7.81025V8.66292C7.81025 8.39069 7.82903 8.11913 7.90554 7.92421C8.11467 7.38036 8.59082 6.81729 9.39022 6.81729C10.4377 6.81729 10.8564 7.65254 10.8564 8.87657V13.7498H13.7499Z'
                      fill='black'
                    />
                  </svg>
                </span>
                LinkedIn
              </a>
              <span className='teamAdvisorInfo'>MBA Graduate, MIT Sloan</span>
              <span className='teamAdvisorInfo'>Founder, Classchoose (acquired by Pioneers Education)</span>
              <span className='teamAdvisorInfo'>Solutions Architect, Epsagon (acquired by Cisco)</span>
            </div>
            <div className='teamAdvisor'>
              <div className='teamAdvisorPhotoOverlay'>
                <img className='teamAdvisorPhoto' src={Howard} alt='Advisor' />
              </div>
              <span className='teamAdvisorName'>Howard Miller</span>
              <span className='teamAdvisorRole'>Advisor</span>
              <a className='teamAdvisorLink' href='https://www.linkedin.com/in/howardjmiller' target='_blank' rel='noreferrer'>
                <span>
                  <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.50527 1.82335C3.50527 2.69795 2.87691 3.39775 1.86795 3.39775H1.84944C0.878039 3.39775 0.25 2.69795 0.25 1.82335C0.25 0.930239 0.897139 0.25 1.887 0.25C2.87691 0.25 3.48649 0.930239 3.50527 1.82335ZM3.31465 4.64101V13.7498H0.421095V4.64101H3.31465ZM13.7499 13.7498L13.75 8.52708C13.75 5.72932 12.3225 4.42714 10.4184 4.42714C8.8821 4.42714 8.19426 5.31122 7.81025 5.93143V4.64129H4.91632C4.95447 5.49599 4.91632 13.75 4.91632 13.75H7.81025V8.66292C7.81025 8.39069 7.82903 8.11913 7.90554 7.92421C8.11467 7.38036 8.59082 6.81729 9.39022 6.81729C10.4377 6.81729 10.8564 7.65254 10.8564 8.87657V13.7498H13.7499Z'
                      fill='black'
                    />
                  </svg>
                </span>
                LinkedIn
              </a>
              <span className='teamAdvisorInfo'>ex-CIO, Columbia Business School</span>
              <span className='teamAdvisorInfo'>CIO, UCLA Anderson School of Business</span>
            </div>
            <div className='teamAdvisor'>
              <div className='teamAdvisorPhotoOverlay'>
                <img className='teamAdvisorPhoto' src={Carly} alt='Advisor' />
              </div>
              <span className='teamAdvisorName'>Carly Burton</span>
              <span className='teamAdvisorRole'>Advisor</span>
              <a className='teamAdvisorLink' href='https://www.linkedin.com/in/carlymarie00006' target='_blank' rel='noreferrer'>
                <span>
                  <svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M3.50527 1.82335C3.50527 2.69795 2.87691 3.39775 1.86795 3.39775H1.84944C0.878039 3.39775 0.25 2.69795 0.25 1.82335C0.25 0.930239 0.897139 0.25 1.887 0.25C2.87691 0.25 3.48649 0.930239 3.50527 1.82335ZM3.31465 4.64101V13.7498H0.421095V4.64101H3.31465ZM13.7499 13.7498L13.75 8.52708C13.75 5.72932 12.3225 4.42714 10.4184 4.42714C8.8821 4.42714 8.19426 5.31122 7.81025 5.93143V4.64129H4.91632C4.95447 5.49599 4.91632 13.75 4.91632 13.75H7.81025V8.66292C7.81025 8.39069 7.82903 8.11913 7.90554 7.92421C8.11467 7.38036 8.59082 6.81729 9.39022 6.81729C10.4377 6.81729 10.8564 7.65254 10.8564 8.87657V13.7498H13.7499Z'
                      fill='black'
                    />
                  </svg>
                </span>
                LinkedIn
              </a>
              <span className='teamAdvisorInfo'>ex-Executive Director, Head of Design, JP Morgan</span>
              <span className='teamAdvisorInfo'>Director of Product Design, Meta</span>
            </div>
          </div>
        </div>
      </div>

      <PioneersEducation />
      <div className='responsibilityHolder' id='responsibility'>
        <h1 className='firstScreenTitle' style={{ textAlign: 'center' }}>
          Corporate Responsibility
        </h1>
        <div className='responsibilityItem'>
          <img className='responsibilityImage' src={Back1} alt='background' />
          <div className='responsibilityInfo'>
            <span className='responsibilityTitle'>Our Commitment to Ethical AI</span>
            <span className='responsibilityText'>
              At Pioneers, we recognize that the integration of Artificial Intelligence in the HR industry raises valid concerns
              about fairness, transparency, and the human element in recruitment. We are deeply committed to ethical AI, and we
              have implemented stringent measures to ensure our algorithms align with these principles.
            </span>
          </div>
          <div className='responsibilityPoints'>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointCount'>01.</span>
              <span className='responsibilityPointTitle'>Transparency</span>
              <span className='responsibilityPointText'>
                We are committed to being transparent about how our algorithms work. We regularly undergo third-party audits to
                ensure compliance with industry standards for fairness and ethics in machine learning.
              </span>
            </div>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointCount'>02.</span>
              <span className='responsibilityPointTitle'>Eliminating Bias</span>
              <span className='responsibilityPointText'>
                One of the cornerstones of our corporate responsibility is the elimination of bias in hiring. Our machine learning
                models are rigorously trained and tested to ensure they do not perpetuate existing societal biases. We continually
                update our algorithms to further reduce the possibility of any unfair advantage or discrimination.
              </span>
            </div>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointCount'>03.</span>
              <span className='responsibilityPointTitle'>Data Privacy</span>
              <span className='responsibilityPointText'>
                We understand the significance of data privacy and are committed to safeguarding all user information. Our data
                storage and processing protocols comply with GDPR and other international data protection regulations.
              </span>
            </div>
          </div>
        </div>

        <div className='responsibilityItem'>
          <img className='responsibilityImage' src={Back2} alt='background' />
          <div className='responsibilityInfo'>
            <span className='responsibilityTitle'>Human-Centric AI</span>
            <span className='responsibilityText'>
              While our technology automates many aspects of the recruitment process, we believe that AI should complement, not
              replace, human skills. We are focused on creating tools that allow HR professionals to spend less time on manual
              tasks and more time on meaningful interactions with candidates and employees.
            </span>
          </div>
          <div className='responsibilityPoints'>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointCount'>01.</span>
              <span className='responsibilityPointTitle'>Community Engagement</span>
              <span className='responsibilityPointText'>
                As part of our commitment to responsible AI, we engage with communities, educational institutions, and industry
                experts to promote fair labor practices and ethical AI development.
              </span>
            </div>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointCount'>02.</span>
              <span className='responsibilityPointTitle'>Sustainable Practices</span>
              <span className='responsibilityPointText'>
                In all our operations, we strive to be environmentally responsible. From minimizing paper waste to optimizing
                energy usage in data centers, our goal is to make our footprint as small as possible.
              </span>
            </div>
          </div>
        </div>

        <div className='responsibilityItem'>
          <img className='responsibilityImage' src={Back3} alt='background' />
          <div className='responsibilityInfo'>
            <span className='responsibilityTitle'>The Road Ahead</span>
          </div>
          <div className='responsibilityPoints'>
            <div className='responsibilityPoint'>
              <span className='responsibilityPointText'>
                We envision a future where AI not only streamlines the hiring process but also makes it more humane and fair.
                Through continuous innovation and ethical practices, we aim to set a new standard in the HR industry.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='footerOld'>© 2024 Pioneers</div>
    </div>
  );
};

export default Investors;
