import './Reviews.scss';
import Logo2 from '../../assets/reviews/logo2.svg';
import Logo3 from '../../assets/reviews/logo3.svg';
import Photo2 from '../../assets/reviews/rev2.jpg';
import Photo3 from '../../assets/reviews/rev3.jpg';

const Reviews = props => {
  return (
    <div className='reviewsHolder'>
      <div className='reviewCard'>
        {!props.withoutLogo && (
          <div className='reviewCardLogo'>
            <img src={Logo2} />
          </div>
        )}
        <div className='reviewCardText'>
          "We are having <span>great success</span> with Jake and Halime already so I am guessing{' '}
          <span>our intern program will grow</span> over the next few months. We've been pretty darn impressed with{' '}
          <span>the quality of the candidates.</span>"
        </div>
        <div className='reviewCardData'>
          <div className='reviewCardPhoto'>
            <img src={Photo2} />
          </div>
          <div className='reviewCardNameData'>
            <span className='reviewCardName'>Andrew Darress,</span>
            <span className='reviewCardRole'>Director, Education</span>
          </div>
        </div>
      </div>

      <div className='reviewCard'>
        {!props.withoutLogo && (
          <div className='reviewCardLogo'>
            <img src={Logo3} />
          </div>
        )}
        <div className='reviewCardText'>
          "It's been a real pleasure working with Raissa, and <span>the quality of the candidate pool is remarkable.</span> Could
          you help us in our search for full-time Product Managers and Software Engineers? And, would it also be possible to find
          good candidates in about two weeks?"
        </div>
        <div className='reviewCardData'>
          <div className='reviewCardPhoto'>
            <img src={Photo3} />
          </div>
          <div className='reviewCardNameData'>
            <span className='reviewCardName'>Gordon Henry,</span>
            <span className='reviewCardRole'>Chief Strategy Officer</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
