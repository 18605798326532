import './Footer.scss';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const Footer = () => {
  const navigate = useNavigate();

  const handleCookieClick = () => {
    window.displayPreferenceModal();
  };

  return (
    <div className='footer'>
      <div className='content'>
        <span>Copyright © {format(new Date(), 'yyyy')} Pioneers Education, Inc. All rights reserved.</span>
        <div className='footerLinks'>
          <span className='footerLink' onClick={() => navigate('/education')}>
            Education
          </span>
          <span className='footerLink' onClick={() => navigate('/engineering')}>
            Engineers
          </span>
          <span className='footerLink' onClick={() => navigate('/marketers')}>
            Marketers
          </span>
          <span className='footerLink' onClick={() => navigate('/assistants')}>
            Assistants
          </span>
          <span className='footerLink' onClick={() => navigate('/healthcare/hire')}>
            Healthcare
          </span>
          <span className='footerLink' onClick={() => navigate('/sales')}>
            Sales
          </span>
          <span className='footerLink' onClick={() => navigate('/blog')}>
            Blog
          </span>
          <span className='footerLink' onClick={() => navigate('/investors')}>
            Investors
          </span>
          <span
            className='footerLink'
            onClick={() =>
              window.open(
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=299f2ef4-42a3-40b7-afb0-9bbe23c90094',
                '_blank',
              )
            }
          >
            Privacy Policy
          </span>
          <span
            className='footerLink'
            onClick={() =>
              window.open(
                'https://app.termly.io/policy-viewer/policy.html?policyUUID=9b6204f8-18a5-4cf3-a92a-bbcee6fe25b9',
                '_blank',
              )
            }
          >
            Terms and Conditions
          </span>
          <span class='footerLink' onClick={handleCookieClick}>
            Consent Preferences
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
